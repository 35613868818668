import Swiper , {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

const swiper = new Swiper('.swiper', {
    modules : [Navigation,Autoplay, Scrollbar],
    slidesPerView:1,
    breakpoints: {
     
      640: {
        slidesPerView: 2,
      }
    },
    speed: 1500,
    direction: 'horizontal',
    disableOnInteraction: true,
    loop: false,
    initialSlide:1,
    autoplay: false,
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },

 
});
 

const swiper2 = new Swiper('.swiper2', {
    modules: [Navigation, Autoplay, Pagination],
    slidesPerView: 1,
    speed: 1500,
    direction: 'horizontal',
    disableOnInteraction: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable:true,
      },
    loop: true,
    initialSlide: 1,
    autoplay: {
        delay: 3000, 
        pauseOnMouseEnter: true, 
        disableOnInteraction: true, 
    },
});


const slides = document.querySelectorAll('.swiper2 .swiper-slide');

slides.forEach((slide) => {
    slide.addEventListener('focus', () => {
        swiper2.autoplay.stop();
    });

    slide.addEventListener('blur', () => {
        swiper2.autoplay.start();
    });
});