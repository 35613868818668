$(".menu-principal-header button, #submenuprincipal-mobile button").on('click', function () {
    alvo = $(this).attr('data-target');
    let estado = $(`#${alvo}`).hasClass('ativa');

    if (estado == true) {
        $(`#${alvo}`).removeClass('ativa');
        $(this).removeClass('ativa');
        $('.submenu-hide-principal').removeClass('ativa');
        $(`#${alvo} .menu`).removeClass('ativa');
    } else {
        $('.menu-principal-header button').removeClass('ativa');
        $('ul.menu').removeClass('ativa');
        $(this).addClass('ativa');
        $('.submenu-hide-principal').addClass('ativa');
        $(`#${alvo}`).addClass('ativa');
        $(`#${alvo} .menu`).addClass('ativa'); 

        const submenu = $(`#${alvo}`);
        const primeiroItemSubmenu = submenu.find('a').first();
        primeiroItemSubmenu.trigger("focus");
    }
});




$('#botao-acionar-menu-acessibilidade-mobile').on('click',function(){
    $('#submenumobile-acessibilidade').toggleClass('d-none');
})
$('#hamburguer-mobile').on('click',function(){
    $('#submenuprincipal-mobile').toggleClass('d-none');
})



$(document).on('ready', function () {
    // Adiciona o evento de keydown ao documento
    $(document).on('keydown', function (e) {
        if (e.key === 'Tab') {
            const submenuAtivo = $('.submenu-hide-principal.ativa');
            const menusAtivos = $('.menu.ativa');

            if (submenuAtivo.length) {
                const itemsSubmenu = submenuAtivo.find('a');
                const focusItem = document.activeElement;
                const indexDoItemFocado = itemsSubmenu.index(focusItem);

                if (indexDoItemFocado === itemsSubmenu.length - 1) {
                    e.preventDefault();

                    const proximoItemMenu = menusAtivos.next('li').find('button, a').first();

                    if (proximoItemMenu.length) {
                        proximoItemMenu.trigger('focus');
                    }
                }
            }
        }
    });


});

$(document).on('focusout', '.submenu-hide-principal.ativa', function (e) {
    const submenu = $(this);
    
    // Verifica se o submenu perdeu o foco completamente
    if (!submenu.is(e.relatedTarget) && !submenu.has(e.relatedTarget).length) {
        submenu.removeClass('ativa');
        submenu.find('.menu').removeClass('ativa');
        $('.menu-principal-header button').removeClass('ativa');
        $('.submenu-hide-principal').removeClass('ativa');
    }
});
