$(document).on('ready',function() {
    // Adiciona um evento de clique ao link com ID "altocontraste"
    $('#altocontraste').on('click', function(e) {
      e.preventDefault(); // Impede o comportamento padrão do link (navegar para outra página)
      
      // Adiciona ou remove a classe "ativa-contrast" na div com ID "recebe-contraste"
      $('#recebe-contraste').toggleClass('ativa-contrast');
    });
  });

  console.log('teste')