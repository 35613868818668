$ ('#arrow-yellow').on ("click", function () {

    $(this).toggleClass('rotacao');
  })

  $("#opcoesacessibilidade").on('click',()=>{alert('Use o ZOOM do seu navegador para ampliar a página, você pode fazer isso também usando as teclas CTRL + no Windows ou Command + no MAC.');})

  $("#voltaranterior").on('click',()=>{    window.history.back();});

  $('#buscador').on('click')




  