let isKeyboardNavigation = false;


$(document).on('keydown', function(e) {
    if (e.key === "Tab") {
        isKeyboardNavigation = true;
    }
});


$("#acionador-busca").on('click', function() {
    $("#buscador").toggleClass('ativa');
    if ($("#buscador").hasClass('ativa')) {
        $("#search").trigger("focus"); 
    }
});


$("#buscador").on('focusout', function(e) {
    setTimeout(function() {
       
        if (isKeyboardNavigation && !$("#buscador").is(":focus") && !$("#buscador input").is(":focus")) {
            $("#buscador").removeClass('ativa'); 
        }
        isKeyboardNavigation = false; 
    }, 100); 
});


$("#buscador input, .filtravel").on('focusin', function() {
    
});


$("#buscador").on('mousedown', function(e) {
   
    isKeyboardNavigation = false; 
});
